import { StageToAtoZSSOTokenEndpoint } from "../constants";
import {
  cleanPhoneNumberFormat,
  getAccessToken,
  getConfirmOtpSession,
  getFromSessionStorage, getIsCanaryFromQueryString,
  getSessionToken,
  isSalesforceDomain,
  removeAccessIdToken,
  removeBbCandidateId,
  removeSessionToken,
  removeUserId,
} from "../helper";
import {CreateCandidateRequest, CreateCandidateRequestPayload, ServiceApiRequestHeader,} from "./serviceApiTypes";
import { USER_SIGN_UP_TYPE } from "src/utils/constants";
import {
  getCountryFullName,
  getCSAuthServiceEndpoint,
  getCountryCode,
  getOrganizationIdFromSession,
  getStage
} from "src/utils/apis/apiHelper";
import { CountryFullName } from "src/utils/commonTypes";
import { getCurrentLocale } from "src/utils/localization/localeHelper";
import {isEmailFieldOptional} from "src/utils/featureFlag/featureFlag";
import { useAWSWAFCaptchaAxios } from "../captcha";
import axios from "axios";

const isNonLocalEnv = process.env.NODE_ENV !== 'development';
const axiosInstance = isNonLocalEnv ? useAWSWAFCaptchaAxios() : axios;

export const getCandidateApi = (request: any) => {
  const sessionToken: string = getSessionToken();
  let requestBody: any = {
    candidateLoginProp: request,
    token: sessionToken,
    countryName: getCountryFullName()
  };
  if (isEmailFieldOptional()) {
    requestBody = {...requestBody, organizationId: getOrganizationIdFromSession('organizationId')}
  }
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.post(`${getCSAuthServiceEndpoint()}/api/candidate/v2?countryCode=${getCountryCode()}`, requestBody, options);
};

export const getSessionTokenApi = (request?: any) => {
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.get(`${getCSAuthServiceEndpoint()}/api/csrf?countryCode=${getCountryCode()}`, options);
};

export const verifySignInApi = (request: { user: string, pin: string }) => {
  const sessionToken: string = getSessionToken();
  let requestBody: any = {
    user: request.user,
    pin: request.pin,
    token: sessionToken,
    countryName: getCountryFullName()
  };
  if (isEmailFieldOptional()) {
    requestBody = {...requestBody, organizationId: getOrganizationIdFromSession('organizationId')}
  }
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.post(
    `${getCSAuthServiceEndpoint()}/api/authentication/verify-sign-in?countryCode=${getCountryCode()}`,
    requestBody,
    options
  );
};

export const signInApi = (request?: any) => {
  const sessionToken: string = getSessionToken();
  const locale = getCurrentLocale();
  let requestBody: any = {
    loginType: request.loginType,
    pin: request.pin,
    user: request.user,
    token: sessionToken,
    locale: locale,
    countryName: getCountryFullName()
  };
  if (isEmailFieldOptional()) {
    requestBody = {...requestBody, organizationId: getOrganizationIdFromSession('organizationId')}
  }
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.post(`${getCSAuthServiceEndpoint()}/api/authentication/sign-in?countryCode=${getCountryCode()}`, requestBody, options);
};

export const confirmOtpApi = (request?: any) => {
  const sessionToken: string = getSessionToken();
  const user = request.user;
  const otp = request.otp;
  const confirmOtpSession = getConfirmOtpSession();

  const requestBody = {
    otp: otp,
    session: confirmOtpSession,
    user: user,
    token: sessionToken,
    countryName: getCountryFullName(),
    countryCode: getCountryCode()
  };

  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.post(`${getCSAuthServiceEndpoint()}/api/authentication/confirm-otp?countryCode=${getCountryCode()}`, requestBody, options);
};

interface checkCandidateEmailExistsRequest{
  email: string;
}

export const checkCandidateEmailExistsApi = (request: checkCandidateEmailExistsRequest) => {
  const sessionToken: string = getSessionToken();
  const accessToken: string = getAccessToken();
  const email = request.email? request.email : "";
  const requestBody = {
    email: email,
    token: sessionToken,
    countryName: getCountryFullName()
  };
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
      "Authorization": accessToken,
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.post(`${getCSAuthServiceEndpoint()}/api/candidate/check-email-exits?countryCode=${getCountryCode()}`, requestBody, options);
};

interface checkPhoneExistsRequest{
  phoneNumber: string;
}

export const checkCandidatePhoneExitsApi = (request: checkPhoneExistsRequest) => {
  const sessionToken: string = getSessionToken();
  const accessToken: string = getAccessToken();
  const phoneNumber = request.phoneNumber? request.phoneNumber : "";
  const requestBody = {
    phoneNumber: cleanPhoneNumberFormat(phoneNumber),
    token: sessionToken,
    countryName: getCountryFullName()
  };
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
      "Authorization": accessToken,
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.post(`${getCSAuthServiceEndpoint()}/api/candidate/check-phone-exits?countryCode=${getCountryCode()}`, requestBody, options);
};

interface checkCandidateExistsRequest {
  phoneNumber: string;
  email: string;
}

// TODO: Remove "throw" when checkCandidateExistsApi is ready to be used. Refer: https://sim.amazon.com/issues/T-RAVENGUARD-1048
export const checkCandidateExistsApi = (request: checkCandidateExistsRequest) => {
  throw new Error('checkCandidateExistsApi is not yet ready to be used');
  const sessionToken: string = getSessionToken();
  const accessToken: string = getAccessToken();
  const phoneNumber = cleanPhoneNumberFormat(request.phoneNumber? request.phoneNumber : "");
  const email = request.email? request.email : "";
  let requestBody: any = {
    phoneNumber: phoneNumber,
    email: email,
    token: sessionToken,
    countryName: getCountryFullName()
  };
  if (isEmailFieldOptional()) {
    requestBody = {...requestBody, organizationId: getOrganizationIdFromSession('organizationId')}
  }
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
      "Authorization": accessToken,
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.post(`${getCSAuthServiceEndpoint()}/api/candidate/check-duplicate?countryCode=${getCountryCode()}`, requestBody, options);
}

export const createCandidateApi = ({request, type}:any) => {
  const sessionToken: string = getSessionToken();
  const accessToken: string = getAccessToken();
  const locale = getCurrentLocale();
  let candidateCreateFrom: CreateCandidateRequestPayload = {
    emailId: isEmailFieldOptional() ? undefined : request.emailId,
    firstName: request.firstName,
    middleName: request.middleName,
    nameSuffix: request.nameSuffix,
    isAgencyUser: request.isAgencyUser,
    isAgreeToCommunication: request.isAgreeToCommunication,
    language: request.language,
    lastName: request.lastName,
    locale: request.locale,
    phoneCountryCode: getCountryFullName() === CountryFullName.US ? '+1' : request.phoneCountryCode,
    phoneNumber: request.phoneNumber,
    pin: request.pin,
    reEnterEmailId: isEmailFieldOptional() ? undefined : request.reEnterEmailId,
    reEnterPhoneNumber: request.reEnterPhoneNumber,
    reEnterPin: request.reEnterPin,
    timezone: request.timezone,
    country: request.country,
    isAgreeToDataRetention: request.isAgreeToDataRetention,
    bbRegion: request.bbRegion
  };
  if (isEmailFieldOptional()){
    candidateCreateFrom = {...candidateCreateFrom, organizationId: getOrganizationIdFromSession('organizationId')}
  }
  //add optional preferred name attributes if they are provided
  const { preferredFirstName, preferredLastName, englishFirstName, englishLastName } = request;

  if(preferredFirstName) candidateCreateFrom.preferredFirstName = preferredFirstName;
  if(preferredLastName) candidateCreateFrom.preferredLastName = preferredLastName;
  if(englishFirstName) candidateCreateFrom.englishFirstName = englishFirstName;
  if(englishLastName) candidateCreateFrom.englishLastName = englishLastName;

  // Add candidateLogin for verifyRegistration
  if(type === USER_SIGN_UP_TYPE.VERIFY) {
    candidateCreateFrom.candidateLogin = request.candidateLogin;
  }
  // check onDemandSyncFlag and destinationUrl in session storage to decide whether need to create SF account
  const onDemandSyncFlag = getFromSessionStorage("onDemandSync");
  const destinationUrlOrig = getFromSessionStorage("destinationUrl") || "";
  const destinationUrl = decodeURIComponent(destinationUrlOrig);
  const skipSalesforceCreate = (onDemandSyncFlag==="true") ? !isSalesforceDomain(destinationUrl) : false;

  const requestBody: CreateCandidateRequest = {
    type:type,
    payload: candidateCreateFrom,
    token: sessionToken,
    locale: locale,
    skipSalesforceCreate: skipSalesforceCreate,
    countryName: getCountryFullName()
  };
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
      "Authorization": accessToken,
    },
    mode: "cors",
    timeout:15000
  };

  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.post(`${getCSAuthServiceEndpoint()}/api/candidate/create?countryCode=${getCountryCode()}`, requestBody, options);
};

export const verifyEmailApi = (request: any) => {
  const sessionToken: string = getSessionToken();
  const accessToken: string = getAccessToken();
  const candidateLogin = request.candidate.candidateLogin;
  const otp = request.otp;
  const confirmOtpSession = getConfirmOtpSession();
  const requestBody = {
    candidateLogin,
    otp,
    session: confirmOtpSession,
    token: sessionToken,
    countryName: getCountryFullName()
  };
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
      "Authorization": accessToken,
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.post(`${getCSAuthServiceEndpoint()}/api/candidate/verify-email?countryCode=${getCountryCode()}`, requestBody, options);
};

export const verifyPhoneApi = (request: any) => {
  const sessionToken: string = getSessionToken();
  const accessToken: string = getAccessToken();
  const candidateLogin = request.candidate.candidateLogin;
  const otp = request.otp;
  // const registrationSession = getRegistrationSession();
  const confirmOtpSession = getConfirmOtpSession();
  let requestBody: any = {
    candidateLogin,
    otp,
    session: confirmOtpSession,
    token: sessionToken,
    countryName: getCountryFullName()
  };
  if (isEmailFieldOptional()) {
    requestBody = {...requestBody, organizationId: getOrganizationIdFromSession('organizationId')}
  }
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
      "Authorization": accessToken,
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.post(`${getCSAuthServiceEndpoint()}/api/candidate/verify-phone?countryCode=${getCountryCode()}`, requestBody, options);
};

export const confirmLoginApi = async () => {
  const res = await axios.get(`${getCSAuthServiceEndpoint()}/oauth2/api/confirm-login?countryCode=${getCountryCode()}`);
  if(res.data.redirectURL) {
     window.location.assign(res.data.redirectURL);
  }
};

export const getConfigApi = () => {
  const sessionToken: string = getSessionToken();
  const requestBody = {
    token: sessionToken
  };
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.get(`${getCSAuthServiceEndpoint()}/api/config?countryCode=${getCountryCode()}`, options);
};

export const signOutApi = (request?: any) => {
  removeAccessIdToken();
  removeUserId();
  removeBbCandidateId();
  // Shallow copy of sessionToken
  const sessionToken: string = `${getSessionToken()}`;
  removeSessionToken();
  const redirectUrl = request && request.redirectUrl? request.redirectUrl : "";
  const requestBody = {
    redirectUrl,
    token: sessionToken
  };
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.post(`${getCSAuthServiceEndpoint()}/api/authentication/sign-out?countryCode=${getCountryCode()}`, requestBody, options);
};

export const forgotPinApi = (request?: any) => {
  const sessionToken: string = getSessionToken();
  const locale =  getCurrentLocale();
  let requestBody: any = {
    user: request,
    token: sessionToken,
    locale: locale,
    countryName: getCountryFullName()
  };
  if (isEmailFieldOptional()) {
    requestBody = {...requestBody, organizationId: getOrganizationIdFromSession('organizationId')}
  }
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.post(`${getCSAuthServiceEndpoint()}/api/authentication/forgot-pin?countryCode=${getCountryCode()}`, requestBody, options);
};

export const updatePinApi = (request?: any) => {
  const sessionToken: string = getSessionToken();
  let requestBody: any = {
    otp: request.otp,
    pin: request.pin,
    user: request.user,
    token: sessionToken,
    countryName: getCountryFullName()
  };
  if (isEmailFieldOptional()) {
    requestBody = {...requestBody, organizationId: getOrganizationIdFromSession('organizationId')}
  }
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.post(`${getCSAuthServiceEndpoint()}/api/authentication/update-pin?countryCode=${getCountryCode()}`, requestBody, options);
};

export const updatePhoneApi = (request?: any) => {
  const sessionToken: string = getSessionToken();
  const accessToken: string = getAccessToken();
  const confirmOtpSession = getConfirmOtpSession();
  const locale = getCurrentLocale();
  const requestBody = {
    phoneNumber: request.phoneNumber,
    phoneCountryCode: request.phoneCountryCode,
    pin: request.pin,
    candidateLogin: request.candidateLogin,
    session: confirmOtpSession,
    token: sessionToken,
    locale: locale,
    countryName: getCountryFullName()
  };
  const options: ServiceApiRequestHeader = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "CSRF-Token": sessionToken,
      "Authorization": accessToken,
    },
    mode: "cors",
    timeout:15000
  };
  if (getIsCanaryFromQueryString()) {
    options.headers['iscanary'] = 'true';
  }
  return axiosInstance.post(`${getCSAuthServiceEndpoint()}/api/candidate/update-phone?countryCode=${getCountryCode()}`, requestBody, options);
};

export const getIPFromAmazon = () => {
  return axiosInstance.get('https://api.ipify.org/?format=json',{timeout: 15000})
}

export const getCountryStateConfig = () => {
  return axiosInstance.get("https://m.media-amazon.com/images/G/01/HVH-Kirin/config/US/state-country-list.json", {timeout: 15000});
}

export const getSSOTokenWithCode = async (code: string) => {
  const endpoint = StageToAtoZSSOTokenEndpoint[getStage()] + `&code=${code}&countryCode=${getCountryCode()}`;
  return axiosInstance.get<{ access_token: string }>(endpoint);
}